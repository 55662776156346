@layer base {
  .main {
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding: var(--sk-space-16);
  border: 2px solid var(--sk-color-grey-100);
  border-radius: var(--sk-radius-16);
  gap: var(--sk-space-24);
  container-name: typecard;
  --icon-button-size: 44px;
  --slots-max-width: 481px; /* Fixed value set by product design decision */
}

.typeInfos {
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  align-items: stretch;
  gap: var(--sk-space-16);
}

.seeMore p {
  line-break: anywhere; /* Long character chains like links break the responsive layout */
}

.badge {
  align-self: flex-start;
}

.slots {
  flex: 0 0 auto;
  align-items: stretch;
  align-self: center;
  width: 100%;
  max-width: var(--slots-max-width);
}

@media (--sk-medium-viewport) {
  .main {
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: center;
    padding: var(--sk-space-24) var(--sk-space-48);
    gap: var(--sk-space-32);
  }

  .slots {
    flex: 0 0 var(--slots-max-width);
    align-self: flex-start;
  }

  .typeInfos {
    min-width: 335px; /* Fixed value set by product design decision */
  }
}

}